<template>
  <div class="wrap">
    <div>
      <div class="bread">
        <img
          class="add"
          src="@/assets/address.png"
        >
        <span>当前位置：</span>
        <router-link to="/">首页</router-link>
        <img
          class="right"
          src="@/assets/breadr.png"
        >
        <router-link to="/contact">联系我们</router-link>
      </div>
    </div>
    <div class="contact-wrap">
      <div class="contact-info">
        <div class="con">
          <div class="address">
            <p class="company">{{info.site_name}}</p>
            <p class="add">地址：<span>{{info.address}}</span></p>
          </div>
          <div class="methods">
            <div>
              <img src="@/assets/kfrx.png">
              <div>
                <p>客服热线</p>
                <p>{{info.service_phone}}</p>
              </div>
            </div>
            <div>
              <img src="@/assets/xsrx.png">
              <div>
                <p>销售热线</p>
                <p>{{info.telephone}}</p>
              </div>
            </div>
            <div>
              <img src="@/assets/yx.png">
              <div>
                <p>邮箱</p>
                <p>{{info.mailbox}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="branch-office">
        <div>
          <img src="@/assets/branch_office_1.png">
          <div>
            <p class="title">舞钢分公司</p>
            <div>
              <p class="text">地址：舞钢市垭口常州路21号</p>
              <p class="text">销售热线：0375-8185009</p>
            </div>
          </div>
        </div>
        <div>
          <img src="@/assets/branch_office_2.png">
          <div>
            <p class="title">驻马店分公司</p>
            <div>
              <p class="text">地址：驻马店市解放路中段，市电业局对面</p>
              <p class="text">销售热线：13938372561</p>
            </div>
          </div>
        </div>
      </div>
      <div class="lxwm">
        <div class="title">
          <img src="@/assets/contact.png">
        </div>
        <div class="form">
          <contact-form />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import contactForm from '@/components/ContactForm'
import { getAllConfig } from '@/api/index'
export default {
  name: 'Contact',
  components: {
    contactForm,
  },
  data() {
    return {
      info: { site_name: '', address: '', telephone: '', mailbox: '',service_phone:'' },
    }
  },
  created() {
    getAllConfig()
      .then((res) => {
        let data = res.data.data
        this.info.site_name = data.filter(
          (item) => item.key === 'site_name'
        )[0].value
        this.info.address = data.filter(
          (item) => item.key === 'address'
        )[0].value
        this.info.telephone = data.filter(
          (item) => item.key === 'telephone'
        )[0].value
        this.info.mailbox = data.filter(
          (item) => item.key === 'mailbox'
        )[0].value
        this.info.service_phone = data.filter(
          (item) => item.key === 'service_phone'
        )[0].value
        
      })
      .catch((err) => {
        console.error(err)
      })
  },
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .bread{
    // width: 1760px;
    margin: 0 auto;
  }
  .wrap {
    background: #f2f2f2;
    padding: 0 20px;
    > .contact-wrap {
      background: #fff;
      > .contact-info {
        background: url('~@/assets/contact_address.png') no-repeat;
        background-size: 100% auto;
        padding: 73px 0 67px 80px;
        > .con {
          > .address {
            > .company {
              font-size: 28px;
              font-weight: bold;
              color: #333333;
              line-height: 40px;
              font-family: PingFangSC-Medium, PingFang SC;
            }
            > .add {
              font-size: 20px;
              color: #333333;
              line-height: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
            }
          }
          > .methods {
            margin-top: 61px;
            > div {
              display: inline-block;
              margin-right: 110px;
              text-align: center;
              > img {
                height: 41px;
              }
              > div {
                > p:nth-child(1) {
                  font-size: 16px;
                  font-weight: 500;
                  color: #333333;
                  line-height: 22px;
                  margin-top: 10px;
                  font-family: PingFangSC-Medium, PingFang SC;
                }
                > p:nth-child(2) {
                  font-size: 20px;
                  color: #3f3f45;
                  line-height: 28px;
                  margin-top: 6px;
                  font-family: PingFangSC-Regular, PingFang SC;
                }
              }
            }
          }
        }
      }
      > .branch-office {
        overflow: hidden;
        margin-top: 50px;
        > div {
          float: left;
          box-sizing: border-box;
          width: 50%;
          padding: 20px 0 20px 112px;
          > img {
            height: 200px;
            width: 200px;
            float: left;
          }
          > div {
            float: left;
            margin-left: 26px;
            > .title {
              margin-top: 23px;
              font-size: 26px;
              font-weight: bold;
              color: #333333;
              line-height: 37px;
              font-family: PingFangSC-Medium, PingFang SC;
            }
            > div {
              margin-top: 46px;
              > .text {
                width: 261px;
                color: #333333;
                line-height: 28px;
                margin-bottom: 14px;
                white-space: nowrap;
                font-family: PingFangSC-Regular, PingFang SC;
              }
            }
          }
        }
        > div:nth-last-child(1) {
          border-left: 1px solid #cccccc;
        }
      }
      > .lxwm {
        > .title {
          text-align: center;
        }
        > .form {
          padding: 0 112px 100px;
          margin-top: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .wrap {
    background: #f2f2f2;
    > .contact-wrap {
      > .contact-info {
        background: url('~@/assets/contact_address_m.png') no-repeat;
        background-size: 100% auto;
        background-color: #fff;
        padding: 0 20px;
        > .con {
          > .address {
            > .company {
              display: none;
            }
            > .add {
              padding-top: 25%;
              font-size: 13px;
              font-weight: bold;
              color: #333333;
              line-height: 24px;
              width: 130px;
              font-family: PingFangSC-Medium, PingFang SC;
              > span {
                display: block;
              }
            }
          }
          > .methods {
            background: #ffffff;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            margin-top: 10%;
            display: flex;
            padding: 20px;
            flex-wrap: wrap;
            align-items: center;
            > div {
              width: 50%;
              display: flex;
              align-items: center;
              > img {
                width: 20px;
              }
              > div {
                margin-left: 10px;
                flex: 1;
                > p:nth-child(1) {
                  font-size: 11px;
                  color: #666666;
                  line-height: 16px;
                  font-family: PingFangSC-Regular, PingFang SC;
                }
                > p:nth-child(2) {
                  font-size: 13px;
                  color: #3f3f45;
                  line-height: 18px;
                  margin-top: 3px;
                  font-family: PingFangSC-Regular, PingFang SC;
                }
              }
            }
            > div:nth-last-child(1) {
              margin-top: 16px;
            }
          }
        }
      }
      > .branch-office {
        background: #fff;
        padding: 30px 15px 0;
        > div {
          margin-bottom: 22px;
          display: flex;
          align-items: center;
          > img {
            height: 80px;
            width: 80px;
          }
          > div {
            flex: 1;
            margin-left: 10px;
            > .title {
              font-size: 15px;
              font-weight: bold;
              color: #333333;
              line-height: 21px;
              margin-top: 3px;
              font-family: PingFangSC-Medium, PingFang SC;
            }
            > div {
              margin-top: 13px;
              > .text {
                font-family: PingFangSC-Regular, PingFang SC;
                font-size: 13px;
                color: #666666;
                line-height: 18px;
                margin-bottom: 4px;
              }
            }
          }
        }
        > div:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
      > .lxwm {
        background: #fff;
        > .title {
          text-align: center;
          > img {
            width: 68%;
          }
        }
        > .form {
          background: #fff;
          padding: 0 15px 20px;
        }
      }
    }
  }
}
</style>